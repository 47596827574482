import { Dispatch } from '@reduxjs/toolkit';
import { userActions } from '../redux/slices/user-slice';
import { offersActions } from '../redux/slices/offers-slice';
import { Offer } from './types';
import axios from 'axios';
import { RootState } from '../redux/ConfigureStore';

const { REACT_APP_VOUCHER_EP_API_URL, REACT_APP_VOUCHER_EP_API_KEY } =
  process.env;

export const getOffers = (type: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(offersActions.loadOffers());
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': REACT_APP_VOUCHER_EP_API_KEY as string,
        Authorization: 'Bearer ' + getState().auth.token,
      },
      params: { type: type },
    };
    try {
      const offersUrl = '/employee-portal/usersvouchers/offers';
      const response = await axios.get<Offer[]>(
        REACT_APP_VOUCHER_EP_API_URL + offersUrl,
        config,
      );
      dispatch(offersActions.setOffers(response.data));
    } catch (err: any) {
      dispatch(
        userActions.addUserError({
          message: err.response?.data?.message ?? 'Your request failed',
          errorCode: err.response?.status
        }),
      );
    }
  };
};
