import axios from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { userActions } from '../redux/slices/user-slice';
import { Campaign } from './types';
import { campaignsActions } from '../redux/slices/campaigns-slice';
import { RootState } from '../redux/ConfigureStore';
const { REACT_APP_VOUCHER_EP_API_URL, REACT_APP_VOUCHER_EP_API_KEY } =
  process.env;

export const getCampaigns = (id: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(campaignsActions.loadCampaigns());
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': REACT_APP_VOUCHER_EP_API_KEY as string,
        Authorization: 'Bearer ' + getState().auth.token,
      },
    };
    try {
      const campaignUrl = '/employee-portal/usersvouchers/offers/' + id + '/campaigns';
      const response = await axios.get<Campaign[]>(
        REACT_APP_VOUCHER_EP_API_URL + campaignUrl,
        config,
      );
      dispatch(campaignsActions.setCampaigns(response.data));
    } catch (err: any) {
      dispatch(
        userActions.addUserError({
          message: err.response?.data?.message ?? 'Your request failed',
          errorCode: err.response?.status
        }),
      );
    }
  };
};
